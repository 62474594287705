import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
    selector: '[detectBrowser]'
})
export class DetectBrowserDirective implements AfterViewInit {

    constructor(private _element: ElementRef) {
    }

    ngAfterViewInit() {
        if (this.isiOS()) {
            this._element.nativeElement.classList.add('ios');
        }
    }

    // detect iOS
    private isiOS(): boolean {
        const sAgent = navigator.userAgent;
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
      // tslint:disable-next-line:no-string-literal
        return (/iPad|iPhone|iPod/.test(sAgent) && !window['MSStream']);
    }

}
