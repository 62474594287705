import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, NgModel } from '@angular/forms';
import { getNewComponentId } from 'ngx-myia-core';
import { Subscription } from 'rxjs';


@Directive({
  // tslint:disable-next-line:directive-selector
    selector: '[formField]'
})
export class FormFieldDirective implements OnInit, OnDestroy {
    get formField(): FormGroup {
        return this._formField;
    }

    @Input() set formField(value: FormGroup) {
        this._formField = value;
        this.initialize();
    }

    @Input() disabled: boolean;
    @Input() validator: any;
    @Input() required: boolean;

    private _formField: FormGroup;
    private _control: FormControl;
    private _controlName: string;
    private _subscription: Subscription;
    private _initialized: boolean;

    constructor(private _model: NgModel) {
        this._subscription = _model.update.subscribe((newValue: any) => {
            this._control.patchValue(newValue);
        });
    }

    ngOnInit() {
        const validators = this.validator ? [this.validator] : [];
        if (this.required) {
            validators.push(Validators.required);
        }
        const controlState = {disabled: this.disabled, value: this._model.model}; // control state must have both two properties
        this._control = new FormControl(controlState, Validators.compose(validators));
        this._controlName = getNewComponentId();
        this.initialize();
    }

    ngOnDestroy() {
        if (this._formField) {
            this._formField.removeControl(this._controlName);
        }
        this._subscription.unsubscribe();
    }

    private initialize() {
        if (this._controlName && this._formField) {
            this._initialized = true;
            this._formField.addControl(this._controlName, this._control);
        }
    }
}
