import { NgModule, ModuleWithProviders } from '@angular/core';
import { CoreModule } from 'ngx-myia-core';
import { AutoFocusDirective } from './directives/autoFocusDirective';
import { DetectBrowserDirective } from './directives/detectBrowserDirective';
import { FormFieldDirective } from './directives/formFieldDirective';
import { HideScrollbarDirective } from './directives/hideScrollbarDirective';
import { HighlightInvalidFieldsDirective } from './directives/highlightInvalidFieldsDirective';
import { ImageLoadedDirective } from './directives/imageLoaderDirective';
import { TransitionGroupComponent, TransitionGroupItemDirective } from './directives/itemTransitionDirectives';
import { MousePanDirective } from './directives/mousePanDirective';
import { NumberOnlyDirective } from './directives/numberOnlyDirective';
import { MouseWheelDirective } from './directives/mouseWheelDirective';
import { ProgressIndicatorCircleComponent } from './components/progressIndicatorCircle';
import { ProgressIndicatorBarComponent } from './components/progressIndicatorBar';
import { SvgIconComponent } from './components/svgIcon.component';
import { OrderByPipe } from './pipes/orderByPipe';
import { RouterActivatedDirective } from './directives/routerActivatedDirective';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtmlPipe';
import { TrackMouseFocusDirective } from './directives/trackMouseFocusDirective';

@NgModule({
  imports: [
    CoreModule,
  ],
  declarations: [
    AutoFocusDirective,
    DetectBrowserDirective,
    FormFieldDirective,
    HideScrollbarDirective,
    HighlightInvalidFieldsDirective,
    ImageLoadedDirective,
    MousePanDirective,
    MouseWheelDirective,
    NumberOnlyDirective,
    OrderByPipe,
    ProgressIndicatorBarComponent,
    ProgressIndicatorCircleComponent,
    RouterActivatedDirective,
    SanitizeHtmlPipe,
    SvgIconComponent,
    TrackMouseFocusDirective,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
  ],
  exports: [
    AutoFocusDirective,
    DetectBrowserDirective,
    FormFieldDirective,
    HideScrollbarDirective,
    HighlightInvalidFieldsDirective,
    ImageLoadedDirective,
    MousePanDirective,
    MouseWheelDirective,
    NumberOnlyDirective,
    ProgressIndicatorBarComponent,
    ProgressIndicatorCircleComponent,
    OrderByPipe,
    RouterActivatedDirective,
    SanitizeHtmlPipe,
    SvgIconComponent,
    TrackMouseFocusDirective,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
  ]
})
export class CoreUIModule {
  static forRoot(): ModuleWithProviders<CoreUIModule> {
    return {
      ngModule: CoreUIModule
    };
  }
}
