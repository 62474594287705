import { Component, Input, HostBinding } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
    selector: 'progress-indicator-circle',
    styleUrls: ['./progressIndicatorCircle.component.scss'],
    template: `
            <div class="circleLoader {{indicatorClass}}">
                <div class="inner"></div>
                <div class="innerSmall"></div>
            </div>
            `
})
export class ProgressIndicatorCircleComponent {
    @Input() indicatorClass: string;

    @HostBinding('class') hostClasses = 'progressIndicatorCircle';
}

