import { Directive, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { getScrollbarWidth } from 'ngx-myia-core';

@Directive({
  // tslint:disable-next-line:directive-selector
    selector: '[hideScrollbar]'
})
export class HideScrollbarDirective implements AfterViewInit {

    constructor(private _element: ElementRef, private _renderer: Renderer2) {
    }

    ngAfterViewInit() {
        const scrollBarWidth = getScrollbarWidth();
        this._renderer.setStyle(this._element.nativeElement, 'right', `-${scrollBarWidth}px`);
    }
}
