import { Component, Input, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'svg-icon',
  styleUrls: ['./svgIcon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <use [attr.xlink:href]="'#' + (prefix || 'svgIcon') + '-' + name"></use>
    </svg>
  `
})
export class SvgIconComponent {

  @Input() prefix: string;
  @Input() name: string;

  @HostBinding('class') get fullClassName(): string {
    return `icon ${this.name}${this.className ? ' ' + this.className : ''}`;
  }

  @Input() className: string;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
  }
}
