import { Directive, ElementRef, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
    selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterViewInit, OnChanges {
    @Input() autoFocus: boolean;

    constructor(private _element: ElementRef) {
    }

    ngAfterViewInit(): any {
        if (this.autoFocus) {
            this.applyFocus();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.autoFocus && changes.autoFocus.currentValue) {
            setTimeout(() => {
                this.applyFocus();
            }, 100);
        }
    }

    private applyFocus() {
        const tagName = this._element.nativeElement.tagName.toLowerCase();
        const inputElement = tagName === 'input' || tagName === 'textarea' ? this._element.nativeElement : this._element.nativeElement.querySelector('input,textarea');
        // check visibility
        if (inputElement.offsetHeight) {
            inputElement.focus();
        } else {
            setTimeout(() => {
                inputElement.focus();
            });
        }
    }
}
