import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[numberOnly]'
})
export class NumberOnlyDirective {

  @Input() positiveOnly: boolean;
  @Input() withDecimals: boolean;

  @Input() min: number = Number.MIN_VALUE;
  @Input() max: number = Number.MAX_VALUE;

  // Allow positive int values
  private regexInt: RegExp = new RegExp(/^[0-9]+$/g);

  // Allow int values and negative
  private regexIntWithNegative: RegExp = new RegExp(/^-?[0-9]+$/g);

  // Allow decimal numbers
  private regexDecimal: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow decimal numbers and negative values
  private regexDecimalWithNegative: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home, up, down
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];

  // - for negative values
  private specialNegativeKeys: Array<string> = ['-'];

  constructor(private el: ElementRef) {
  }

  @HostListener('blur', ['$event'])
  onBlur(event: KeyboardEvent) {
    // validate value
    const current: string = this.el.nativeElement.value;
    const currentValue = (this.withDecimals ? parseFloat(current) : parseInt(current, 10)) || (this.min === Number.MIN_VALUE ? 0 : this.min);
    if (currentValue > this.max) {
      this.setInputValue(this.max);
    }
    if (currentValue < this.min) {
      this.setInputValue(this.min);
    }

  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow special keys
    if (this.specialKeys.indexOf(event.key) !== -1 || (!this.positiveOnly && this.specialNegativeKeys.indexOf(event.key) !== -1)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    if (event.key === 'ArrowUp') {
      const currentValue = (this.withDecimals ? parseFloat(current) : parseInt(current, 10)) || (this.min === Number.MIN_VALUE ? 0 : this.min);
      const newValue = currentValue + 1;
      if (newValue <= this.max) {
        this.setInputValue(newValue);
      } else {
        this.setInputValue(currentValue);
      }
      event.preventDefault();
    }
    if (event.key === 'ArrowDown') {
      const currentValue = (this.withDecimals ? parseFloat(current) : parseInt(current, 10)) || (this.min === Number.MIN_VALUE ? 0 : this.min);
      const newValue = currentValue - 1;
      if (newValue >= this.min) {
        this.setInputValue(newValue);
      } else {
        this.setInputValue(currentValue);
      }
      event.preventDefault();
    }
    const next: string = current.concat(event.key);
    const regEx = this.withDecimals ? (this.positiveOnly ? this.regexDecimal : this.regexDecimalWithNegative) : (this.positiveOnly ? this.regexInt : this.regexIntWithNegative);
    if (next && !String(next).match(regEx)) {
      event.preventDefault();
    }
  }

  private setInputValue(value: number) {
    this.el.nativeElement.value = value;
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }
}
