import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {}

    transform(v: any) : SafeHtml {
        if (v && v.changingThisBreaksApplicationSecurity) {
            return v;
        }
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }
}
