import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
    selector: 'progress-indicator-bar',
    styleUrls: ['./progressIndicatorBar.component.scss'],
    template: `
            <div class="barLoader {{indicatorClass}}"></div>
            `
})
export class ProgressIndicatorBarComponent {
    @Input() indicatorClass: string;
    @HostBinding('class') hostClasses = 'progressIndicatorBar';

}
